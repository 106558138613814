import { css } from '@emotion/react'
import { formatTimestampDdMmYyyy } from '@orus.eu/calendar-date'
import { memo } from 'react'
import { colorTokens, spacing } from '../../../../foundation'
import { useTranslate } from '../../../../localization'
import { Dialog } from '../../../../patterns/dialog/dialog'
import { Divider, Text } from '../../../atoms'

export const ValidationDialogRowContent = function ValidationDialogRowContent({
  title,
  text,
}: {
  title: string
  text: string
}): JSX.Element {
  return (
    <>
      <Text variant="body2Medium" color={colorTokens['color-text-base-main']}>
        {title}
      </Text>
      <Text variant="body2" color={colorTokens['color-text-base-basic']}>
        {text}
      </Text>
    </>
  )
}

export type SepaMandateValidationDialogProps = {
  onHide: () => void
  onSubmit: () => void
  firstName: string
  lastName: string
  iban: string
  isUpdatePayment?: boolean
  isLoading?: boolean
}

export const SepaMandateValidationDialog = memo<SepaMandateValidationDialogProps>(function SepaMandateValidationDialog({
  onHide,
  onSubmit,
  firstName,
  iban,
  lastName,
  isUpdatePayment,
  isLoading,
}): JSX.Element {
  const translate = useTranslate()

  return (
    <Dialog
      onClose={onHide}
      title={translate('sepa_mandate')}
      size="medium"
      onPrimaryAction={() => onSubmit()}
      primaryActionLabel={translate(isUpdatePayment ? 'validate' : 'pay_and_finalize_subscription')}
      primaryActionLoading={isLoading}
      onSecondaryAction={onHide}
      secondaryActionLabel={translate('cancel')}
    >
      <div
        css={css`
          width: 100%;
        `}
      >
        <ValidationDialogRowContent
          title={translate('implementation_date')}
          text={formatTimestampDdMmYyyy(Date.now())}
        />
        <ValidationDialogDivider />
        <ValidationDialogRowContent title={translate('type')} text={translate('recurring')} />
        <ValidationDialogDivider />
        <ValidationDialogRowContent
          title={translate('creditor')}
          text={' Orus (FR9305V85966A), 5 Av. du Général de Gaulle, 94160 Saint-Mandé, France'}
        />
        <ValidationDialogDivider />
        <ValidationDialogRowContent title={translate('payer')} text={`${firstName} ${lastName} (${iban})`} />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${spacing[40]};
            margin-top: ${spacing[70]};
          `}
        >
          <Text variant="caption" color={colorTokens['color-text-base-disable']}>
            {translate('sepa_payment_notice')}
          </Text>
          <Text variant="caption" color={colorTokens['color-text-base-disable']}>
            {translate('sepa_authorization')}
          </Text>
        </div>
      </div>
    </Dialog>
  )
})

export const ValidationDialogDivider = function ValidationDialogDivider(): JSX.Element {
  return (
    <Divider
      orientation="horizontal"
      css={css`
        margin: ${spacing[40]} 0 ${spacing[40]} 0;
      `}
    />
  )
}
